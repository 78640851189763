import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Main from "./layouts/Main";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";
import Inside from "./pages/Inside";
import LandingPage from "./pages/Landing";
import LoginPage from "./pages/Login";
import MySims from "./pages/MySims";
import Plan from "./pages/Plan";
import RegisterPage from "./pages/Register";
import ForgotPassword from './pages/ForgotPassword';
import { PrivateRoute } from './components/PrivateRoute';
import { history } from './helpers/history';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Payment from './pages/Payment';
import UserManage from './pages/UserManage';

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [stripePromise, setStripePromise] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const packageId = useSelector((x) => x.eSim.packageId);
  useEffect(() => {
    getPublicKey();
    createPaymentIntent();
  }, []);

  const getPublicKey = async () => {
    try {
      axios.get(`${baseUrl}/api/config/publicKey`).then(async (res) => {
        const publicKey = await res.data.publicKey;
        setStripePromise(loadStripe(publicKey));
      });
    } catch (err) {
      
    }
  };
  const createPaymentIntent = async () => {
    try {
      axios
        .post(`${baseUrl}/api/payment/createPaymentIntent`, { packageId : packageId })
        .then(async (res) => {
          const cs = await res.data.clientSecret;
          setClientSecret(cs);
        });
    } catch (err) {
      
    }
  };
  return (
    <Main>
      <Routes>
        <Route exact path='/' element={<LandingPage />} />
        <Route exact path='/login' element={<LoginPage />} />
        <Route exact path='/register' element={<RegisterPage />} />
        <Route exact path='/plan/:location_id' element={<Plan />} />
        <Route exact path ='/forgot' element={<ForgotPassword />} />
        <Route element={<PrivateRoute />}>
          <Route exact path='/account' element={<Account />} />
          <Route exact path='/checkout' element={
            stripePromise &&
            <Elements stripe={stripePromise} options={{clientSecret}}>
              <Checkout />
            </Elements>
          } />
          <Route exact path="/usermanage" element={<UserManage />} />
          <Route exact path='/payment' element={<Payment />} />
          <Route exact path='/inside/:account_id' element={<Inside />} />
          <Route exact path='/mysims' element={<MySims />} />
        </Route>
      </Routes>
    </Main>
  );
}

export default App;
