import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { getCountryCode } from "../util/countryUtil";
import Flags from "country-flag-icons/react/1x1";

const Checkout = () => {
  const [stripePromise, setStripePromise] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const auth = useSelector((x) => x.auth.value);
  const dataSize = useSelector((x) => x.eSim.dataSize);
  const minute = useSelector((x) => x.eSim.minute);
  const cost = useSelector((x) => x.eSim.cost);
  const countryName = useSelector((x) => x.eSim.countryName);
  const period = useSelector((x) => x.eSim.period);
  const packageId = useSelector((x) => x.eSim.packageId);
  const [email, setEmail] = useState(auth?.email);
  const [username, setUsername] = useState(auth?.username);
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState(""); 
  const [countryCode, setCountryCode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [exist, setExist] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if(!cost || cost === 0 || cost === '')window.location.href=`${window.location.origin}`;
    const code = getCountryCode(countryName);
    setCountryCode(code);
    getAccount();
    getPublicKey();
    createPaymentIntent();
  }, []);
  
  const getPublicKey = async () => {
    try {
      axios.get(`${baseUrl}/api/config/publicKey`).then(async (res) => {
        const publicKey = await res.data.publicKey;
        setStripePromise(loadStripe(publicKey));
      });
    } catch (err) {
      window.location.href=`${window.location.origin}`;
    }
  };

  const createPaymentIntent = async () => {
    try {
      axios
        .post(`${baseUrl}/api/payment/createPaymentIntent`, { packageId : packageId , enabled : true})
        .then(async (res) => {
          const cs = await res.data.clientSecret;
          setClientSecret(cs);
        });
    } catch (err) {
      window.location.href=`${window.location.origin}`;
    }
  };

  const getAccount = async () => {
    try {
      const res = await axios.post(`${baseUrl}/api/auth/getAccount`, {
        email,
      });
      if(res.data.address && res.data.address !== "")setExist(true);
      setAddress(res.data.address);
      setCity(res.data.city);
      setCountry(res.data.country);
      setPostalCode(res.data.postcode);
    } catch (err) {
      setExist(false);
    }
  };
  
  const handlePostalCodeChange = (event) => {
    const inputPostalCode = event.target.value;
    const isValidPostalCode = /^[0-9]{5}(?:-[0-9]{4})?$/.test(inputPostalCode);
    setIsValid(isValidPostalCode);
    setPostalCode(inputPostalCode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!exist)await axios.post(`${baseUrl}/api/auth/setAccount`, {
      email : email,
      country : country,
      address : address,
      city : city,
      postcode : postalCode
    });
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setErrorMessage(submitError.message);
      return;
    }

    const res = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment`,
      },
    });
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      console.log("Payment succeeded!");
    }
  };

  return (
    <div className="w-full sm:w-[80%] mx-auto sm:mb-[70px]">
      <div className="w-full h-auto px-10 pt-2">
        <Form onSubmit={handleSubmit} className="w-full">
          {errorMessage && (
            <label className="text-[rgb(255,0,0)]">{errorMessage}</label>
          )}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Email <font color="red">*</font>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Name <font color="red">*</font>
            </Form.Label>
            <Form.Control
              type="input"
              placeholder="Your Full Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
            <Form.Label>
              Billing Details <font color="red">*</font>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              className="mt-1"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
            <Form.Control
              type="address"
              placeholder="Address"
              className="mt-1"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            <InputGroup className="mt-1 mb-3">
              <Form.Control
                aria-label="City"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
              <Form.Control
                aria-label="Post Code"
                placeholder="Post Code"
                value={postalCode}
                onChange={handlePostalCodeChange}
                required
                style={!isValid ? { color: "red" } : {}}
              />
            </InputGroup>
          </Form.Group>
          <div className="pay-part">
            <InputGroup className="flex mb-3 flex-nowrap">
              <Button
                size="lg"
                variant="outline-dark items-center"
                style={{ display: "flex" }}
                className="w-full text-gray-700"
              >
                <img src="/images/card.png" className="w-[20%]  sm:w-[100px]" alt="" />
                <span>CARD</span>
              </Button>
              <Button
                size="lg"
                variant="outline-dark items-center"
                style={{ display: "flex" }}
                className="w-full"
              >
                <img src="/images/applepay.png" className="w-[20%] sm:w-[100px]" alt="" />
                <span>APPLE PAY</span>
              </Button>
            </InputGroup>
          <Form.Label>
              Payment Information <font color="red">*</font>
            </Form.Label>
              {/* <div>
                <CardNumberElement className="paymentInput" />
              </div>
              <div>
                <CardExpiryElement className="paymentInput" />
              </div>
              <div>
                <CardCvcElement className="paymentInput" />
              </div> */}
            {stripePromise && (
              <PaymentElement
                stripe={stripePromise}
                options={{ clientSecret }}
              />
            )}
          </div>
          <div className="px-5 py-3" style={{ backgroundColor: "#00274C" }}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col items-start justify-between text-white ">
                <h4>{dataSize} GB</h4>
                <h6>{minute} MINUTES</h6>
              </div>
              <div className="flex flex-col items-start justify-between text-white">
                <p>TOTAL</p>
                <h4>€ {cost} EUR</h4>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center justify-between text-white ">
                <span className="mr-5">eSIM</span>
                <div className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
                  {countryCode ? (
                    <div className="w-full h-full">
                      {Flags[countryCode]({ title: countryName })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start justify-between ml-3 text-white">
                <h6>{countryName}</h6>
                <span>VALID FOR {period} DAYS</span>
              </div>
            </div>
            <Button
              type="submit"
              variant="outline-light"
              className="w-full mb-[50px]"
              disabled={!stripe || !elements}
            >
              Pay
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Checkout;
